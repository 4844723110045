import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';

type DataProps = {
    site: {
        siteMetadata: {
            title: string;
            mainSiteUrl: string;
            social: {
                twitter: string;
                reddit: string;
            };
        };
    };
};

const NotFoundPage: FC<PageProps<DataProps>> = ({ data, location }) => {
    const { title, mainSiteUrl, social } = data.site.siteMetadata;

    return (
        <div className="flex flex-col h-screen pt-16 pb-12 bg-white">
            <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex justify-center flex-shrink-0">
                    <a href="/" className="inline-flex">
                        <span className="sr-only">{title}</span>
                        <StaticImage
                            layout="fixed"
                            formats={['auto', 'webp', 'avif']}
                            src="../images/femscat-icon.png"
                            quality={95}
                            height={96}
                            alt="FemScat logo"
                        />
                    </a>
                </div>
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-sm font-semibold tracking-wide text-indigo-600 uppercase">
                            404!
                        </p>
                        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                            Page not found.
                        </h1>
                        <p className="mt-2 text-base text-gray-500">
                            Sorry, we couldn't find the page you're looking for.
                        </p>
                        <div className="mt-6">
                            <a
                                href="/"
                                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                            >
                                Go back to {title} home
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="flex-shrink-0 w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <nav className="flex justify-center space-x-4">
                    <a
                        href={mainSiteUrl}
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        FemScat Site
                    </a>
                    <span
                        className="inline-block border-l border-gray-300"
                        aria-hidden="true"
                    />
                    <a
                        href={`https://reddit.com/u/${social.reddit}`}
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        Reddit
                    </a>
                    <span
                        className="inline-block border-l border-gray-300"
                        aria-hidden="true"
                    />
                    <a
                        href={`https://twitter.com/${social.twitter}`}
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        Twitter
                    </a>
                </nav>
            </footer>
        </div>
    );
};

export default NotFoundPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
    }
`;
